@import 'variables';
@import 'mixins';
@import 'crow';

html {
	overflow-y: scroll;
	min-height: 100%;
	padding-bottom: 1px;
}
body,
html {
	width: 100%;
	height: 100%;
	margin: 0;
	background: $lightGray;
	font-family: 'Verdana';
	min-height: 100%;
	padding-bottom: 1px;
	#root {
		height: 100%;
		width: 100%;
		min-height: 100%;
		padding-bottom: 1px;
	}
}
