$auto-widths-iterations: 12; /* Set to how many columns you want enable for auto fly operation */
$percent-widths-iterations: 100; /* Set to percentages, defaults to 1,2,3,4,5 till 100 */
$unit-widths-iterations: 12; /* Set to the number of spans you want, default 12 */
$gutter: 15; /* Set to what gutter you want crow to use, to remove gutter use class "no-gutter", don't set this value to 0 */

*,
*:before,
*:after {
  box-sizing: inherit;
}
html {
  box-sizing: border-box;
}

@mixin auto-widths($auto-widths-iterations) {
  @for $i from 1 through $auto-widths-iterations {
    > *:first-child:nth-last-child(#{$i}),
    > *:first-child:nth-last-child(#{$i}) ~ * {
      $percent : 100/$i;
      width: #{$percent}%();
    }
  }
}

@mixin percent-widths($percent-widths-iterations) {
  @for $i from 1 through $percent-widths-iterations {
    .w-#{$i} {
      $percent : $i;
      width: #{$percent}%();
    }
  }
}
@mixin unit-widths($unit-widths-iterations) {
  @for $i from 1 through $unit-widths-iterations {
    .ws-#{$i} {
      $percent: 100*$i/$unit-widths-iterations;
      width: #{$percent}%();
    }
  }
}

@mixin crowify {
  text-align: center;
  font-size: 0 !important;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
  > * {
    vertical-align: middle;
    display: inline-block;
    font-size: medium; /* IE */
    font-size: initial;
    text-align: left;
    margin: 0;
    padding: 0;
  }
  &.fly {
    @include auto-widths($auto-widths-iterations);
  }
  &.up {
    > * {
      vertical-align: top;
    }
  }
  &.down {
    > * {
      vertical-align: bottom;
    }
  }
  > * {
    &.up {
      vertical-align: top;
    }
    &.down {
      vertical-align: bottom;
    }
  }
}

@mixin flex-crowify {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  &.fly {
    > * {
      flex-grow: 1;
    }
  }
  &.up {
    align-items: flex-start;
  }
  &.down {
    align-items: flex-end;
  }
  > * {
    &.up {
      align-self: flex-start;
    }
    &.down {
      align-self: flex-end;
    }
  }
}

.crow {
  @include crowify();
  @include percent-widths($percent-widths-iterations);
  @include unit-widths($unit-widths-iterations);
}

.crow {
  &.gutter {
    padding-right: ($gutter*1px);
    padding-left: ($gutter*1px);
    margin-right: (- $gutter*1px);
    margin-left: (- $gutter*1px);
    > * {
      padding-right: ($gutter*1px);
      padding-left: ($gutter*1px);
    }
  }
}

.nest {
  width: 960px;
  padding-right: ($gutter*1px);
  padding-left: ($gutter*1px);
  margin-right: auto;
  margin-left: auto;
}

@mixin uncrowify {
  &.crow {
    height: auto;
    display: block;
    > * {
      display: block;
      width: auto;
    }
    &:before {
      content: '';
      display: none;
    }
  }
}