@import 'variables';
@import 'mixins';

.video-react-control-bar,
.video-react-big-play-button,
.video-react-loading-spinner {
  display: none !important;
}
.App {
  -webkit-font-smoothing: antialiased;
  text-align: center;
  width: 100%;
  height: 100%;
  .centerFull {
    width: 100%;
    height: 100%;
  }
  img {
    width: auto;
    max-height: 400px;
  }
  .logo {
    max-height: 400px;
  }
  .masonry {
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
  }
}
